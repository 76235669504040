import React from 'react';
import { graphql } from 'gatsby';
import Flex, { FlexItem } from 'styled-flex-component';

import { useTrackedState } from '../lib/state';

import SubLayout from '../components/SubLayout';
import { P } from '../components/common/P.style';
import { Img } from '../components/common/Img.style';
import Link from '../components/Link';
import { H2, H2Background, H3, H4 } from '../components/common/H.style';
import { UL } from '../components/common/UL.style';
import { LI } from '../components/common/LI.style';
import { DRLI } from '../components/common/DRLI.style';
import ButtonList from '../components/ButtonList';
import DepartmentSchedule from '../components/DepartmentSchedule';
import DoctorInCharge from '../components/DoctorInCharge';
import SymptomExamples from '../components/SymptomExamples';
import DrCard from '../components/DrCard';
import Modal from '../components/Modal';

import onlineHero from '../assets/img/telemed-top_bana.jpg';
import QRcode from '../assets/img/line-qr.jpg';
import HirahataImg from '../assets/img/hirahata.jpg';
import KamiyamaImg from '../assets/img/kamiyama.jpg';
import TsumiyamaImg from '../assets/img/tsumiyama.jpg';
import OkuraImg from '../assets/img/okura.jpg';
import HironoImg from '../assets/img/hirono.jpg';
import NagoshiImg from '../assets/img/nagoshi.jpg';
import appImg from '../assets/img/telemed-link_2.jpg';


const applicationLink = 'https://telemed-app.hirahata-clinic.or.jp/app_form.html?locate=shibuya';

const DentalArticleList = ({ list = [] }) => (
  <Flex column>
    {list.map(({ id, date, title, path }, index) => (
      <Flex
        key={id}
        css={`
          height: 60px;
          margin: 5px 0;
          border-bottom: ${(list.length - 1 !== index && 'solid 1px #ccc') || 'inherit'};
        `}
      >
        <Flex center>
          <span
            css={`
              font-size: 12px;
              color: #666666;
              margin-right: 30px;
            `}
          >
            {date}
          </span>
        </Flex>
        <Flex center>
          <Link to={path}>
            <span
              css={`
                font-size: 16px;
                color: #3b4043;
                overflow-wrap: anywhere;
              `}
            >
              {title}
            </span>
          </Link>
        </Flex>
      </Flex>
    ))}
  </Flex>
);

const ModalBodyHirahata = () => (
  <div
    css={`
      width: 90%;
      text-align: left;
    `}
    >
    <P>
      山形大学医学部卒業。<br />
      東邦大学大橋病院消化器内科で大腸カメラ挿入時の疼痛、胃酸逆流に伴う症状などについて研究。<br />
      胃腸疾患のほか、膵炎など、消化器全般の診療にたずさわった。<br />
      2008年7月よりヒラハタクリニック院長。<br />
      医療向けIT企業（株）メイドインクリニックを設立。<br />
    </P>
    <UL>
      <LI>日本消化器内視鏡学会専門医</LI>
      <LI>日本医師会認定産業医</LI>
      <LI>日本内科学会認定医</LI>
      <LI>旧通産省認定 第一種情報処理技術者 LPIC Level 1取得</LI>
    </UL>
  </div>
);

const ModalHead = () => (
  <div
    css={`
      width: 90%;
    `}
    >
    <Flex
      column
      center
      css={`
        margin: 30px 0;
      `}
      >
      <span
        css={`
          font-size: 16px;
          color: #3b4043;
        `}
        >
        経歴等
      </span>
    </Flex>
    <hr />
  </div>
);

const Telemedicine = ({ data: { allMarkdownRemark, allFile } }) => {
  const { size } = useTrackedState();
  const dentalArticles = allMarkdownRemark.edges
    .filter(
      edge => !!edge.node.frontmatter.date
    )
    .map(({ node: { id, frontmatter: { title, path } } }) => ({
      id,
      title,
      path,
    }));
  return (
    <SubLayout topTitle="オンライン診療">
      <Img src={onlineHero} />
      <Flex
        justifyAround
        wrap="true"
        css={`
        margin: 20px 0;
      `}
      >
        <DepartmentSchedule
          list={[
            { day: '月～土曜日', time: '9:30～12:30', time2: '14:00〜20:00' },
            { day: '日曜日・祝日', time: '9:00～11:00', time2: '15:00〜20:00' },
          ]}
          size={size}
        />
      </Flex>
      <H3>オンライン診療について</H3>
      <P>
        当院では、感染予防、そして全国の患者様のニーズに応えるために、積極的にオンライン診療を行っております。<br />
      </P>
      
      <H3>必要なもの</H3>
      <P>
        必要なのは下記のみ。
      </P>
      <UL>
        <LI>スマートフォンやタブレット等、通話可能な電子機器</LI>
        <LI>保険証</LI>
        <LI>クレジットカード（VISA Mastercard JCB Diners American Express）</LI>
        <LI>薬局の情報（申込ページで全国の薬局が検索可能）</LI>
      </UL>

      <H3>診察の流れ（かんたん５ステップ）</H3>
      <P>
        1. ウェブで申し込み<br />
        2. 医師からの電話<br />
        3. （必要があれば）ビデオ通話<br />
        4. クレジットカード支払<br />
        5. 薬局で薬をお受け取り
      </P>
      <ButtonList
        size={size}
        list={[
          { link: '/nagare', title: '診察の流れ' },
          { link: '/qanda', title: 'よくある質問' },
        ]}
      />
      <P>
        スマートフォンとの相性で当院のビデオ通話システムが使えない場合に、LINEを使用することがあります（クリニックからの発信専用）<br />
        その際はこちらのQRコードより友だち登録をお願いいたします。<br />
        <div 
            css='margin: 1em;
            text-align:center;
        '>
        <Img src={QRcode} css='width: 150px;'/>
        </div>
      </P>

      <H3>利用料金</H3>
      <P>
        保険分の支払いの他に、「情報通信機器の運用に要する費用」として1,000円いただきます。<br />
        ３割負担の場合、新患の場合で1900円前後、再診の場合で1300円前後の支払いになることがほとんどです。<br />
        その他に、薬局で薬代などの費用が発生します。<br />
      </P>

      <H3>担当医</H3>
      <P>
      <DrCard>
        <H4>平畑 光一 院長</H4>
        <p>
         （消化器内科、内科一般、新型コロナ後遺症）<br />
          月・火・木・金・土・日<br />
          9:00～20:00
        </p>
        <Img src={HirahataImg} css={'width: 150px; border-radius: 6px;'}/>
      </DrCard>
      <DrCard>
        <H4>神山 ほなみ 医師</H4>
        <p>（糖尿病、内分泌、内科一般）<br />
          月・水・木<br />9:00～12:30、14:00～16:45</p>
        <Img src={KamiyamaImg} css={'width: 150px; border-radius: 6px;'}/>
      </DrCard>
      <DrCard>
        <H4>大倉 遊 医師</H4>
        <p>（消化器外科、肛門科、内科一般）<br />
          月・火・金<br />18：00～20：00</p>
        <Img src={OkuraImg} css={'width: 150px; border-radius: 6px;'}/>
      </DrCard>
        <DrCard>
          <H4>名越 真理子 医師</H4>
          <p>（内視鏡）<br />
            月・木・金・土<br />9:00～12:30</p>
          <Img src={NagoshiImg} css={'width: 150px; border-radius: 6px;'}/>
        </DrCard>
      </P>
      
      <H3>絶対に営業しない歯科</H3>
      <Flex
        justifyAround
        wrap="true"
        css={`
          margin: 20px 0;
        `}
        >
        <DepartmentSchedule
          list={[
            { day: '木曜日', time: '9:00〜12:00' },
          ]}
          size={size}
          />
        <DoctorInCharge lines={['廣野 大紀 歯科医師']} />
      </Flex>
      <P>
        当院では、廣野先生に歯科のオンライン診療をしていただいています。<b>（木曜午前のみ）</b><br />
        １回1500円でしっかり丁寧に相談に乗っていただいています。<br />
        当院では歯科治療ができませんので、一切の営業抜きで、患者さん側の視点でアドバイスをしていただいています。<br />
        「かかりつけの歯医者に言われた料金が高い気がするけど、適正なのか？」<br />
        「インプラントについて疑問がある」など、なにかあればお気軽にご相談ください。
        <DrCard>
          <Flex>
            <FlexItem css={`
              margin: 0 1.5em;
              line-height: 1.8;
              font-size: 18px;
              `}>
          <H4>廣野 大紀 歯科医師</H4>
          <Img src={HironoImg} css={'width: 150px; border-radius: 6px;'}/> 
            </FlexItem>
            </Flex>
          <P css={`
              margin: 0 1.5em;
              line-height: 1.8;
              text-align: left;
          `}>
            2013年、練馬区の病院にて研修<br />
            2014年、都内のクリニックにて勤務<br />
            2018年、ドバイ留学<br />
            2019年、帰国後神奈川県のクリニックに勤務中</P>
        </DrCard>
      </P>
      <P>
        必要があれば、当院の内科のオンライン診療に引き継いで、薬を処方することもできます。<br />
        （その場合、「システム利用料」の1000円はいただきません。）
        
        <H4>絶対に営業しない歯科のブログ</H4>
        <DentalArticleList list={dentalArticles} />
      </P>

      

      <div css={`
          max-width:500px;
          margin:5em auto 3em;
          text-align:center;
      `}>
      <a href={applicationLink}>
        <Img src={appImg} css={'margin-bottom:2em;'}/>
        お申し込みはこちら
      </a>
      </div>
      
     </SubLayout>
  );
};

export default Telemedicine;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { fileAbsolutePath: { regex: "/dental-articles/" } }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 500)
          frontmatter {
            date(formatString: "YYYY/MM/DD")
            title
            path
          }
        }
      }
    }
  }
`;
