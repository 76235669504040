import React from 'react';
import styled from 'styled-components';
import Flex from 'styled-flex-component';

const Wrapper = styled(Flex)`
  width: ${({ width }) => width};
  max-width: 600px;
  box-sizing: border-box;
  border-radius: 6px;
  border: solid 1px #999999;
  background-color: #ffffff;
  margin: 2em auto 1em;
`;

const Inner = styled(Flex)`
  margin: 20px auto;
  width: ${({ width }) => width};
  line-height: 1.88;
  text-align:center;
`;

const DrCard = ({ children, width = 'inherit' }) => (
  <Wrapper>
    <Inner column center>
      {children}
    </Inner>
  </Wrapper>
);

export default DrCard;
